import { Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { validateAccessKey } from '../services/api';
import { logError, logWarn } from '../utils/logger';
import { showErrorToast } from '../utils/toastUtils';

const BetaAccess: React.FC = () => {
  const [accessKey, setAccessKey] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { validateBetaAccess, betaAccessValidated } = useAuth();
  const navigate = useNavigate();
  // const pageRefresh = useRef(true);

  useEffect(() => {

    if (betaAccessValidated) {
        logWarn('User has already validated beta access');
      navigate('/beta/store'); // Redirect if the user has already validated
    }
  }, [betaAccessValidated, navigate]);

  const handleAccessKeySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    logWarn('Validating access key', { accessKey });
    try {
      const response = await validateAccessKey(accessKey);
      if (response.success) {
        // showSuccessToast('Access key validated successfully!');
        logWarn('Access key validated successfully');
        validateBetaAccess(); // Update AuthContext
        navigate('/beta/store'); // Redirect to the store
      } else {
        logError('Invalid access key');
        setError('Invalid access key.');
        showErrorToast('Invalid access key.');
      }
    } catch (err: any) {
      const errorMessage = err.message || 'Error validating access key.';
      setError(errorMessage);
      showErrorToast(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Card sx={{ maxWidth: 450 }}>
        <CardContent>
          <Typography variant="h5" align="center">Beta Access</Typography>
          <Typography variant="body2" color="text.secondary" sx={{mt:2, mb:2}}>
            Please enter your beta access key to proceed to the store. If you encounter issues, contact support.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Access Key"
            error={!!error}
            helperText={error}
            type="text"
            fullWidth
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAccessKeySubmit(e);
            }}
          />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleAccessKeySubmit}
            >
              Submit
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default BetaAccess;
