import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BetaAccess from '../components/BetaAccess';
import NotFound from '../components/Landing/NotFound';
import Success from '../components/Landing/Success';
import Login from '../components/Login';
import Redeem from '../components/RedeemProduct/Redeem';
import Register from '../components/Register';
import Storefront from '../components/Store/BetaStorefront';
import { useAuth } from '../contexts/AuthContext';

const AppRoutes: React.FC = () => {
  const { betaAccessValidated } = useAuth();

  return (
    <Routes>
      <Route path="/beta-access" element={<BetaAccess /> } />
      {/* <Route
      path="/beta/*"
      element={
        user || betaAccessValidated ? <BetaRoutes /> : <Navigate to="/beta-access" replace />
      }
    /> */}
      <Route path="/beta/store" element={
        betaAccessValidated ? <Storefront /> : <Navigate to="/beta-access" replace />
        } />
      <Route path ="/beta/register" element={<Register mode="giftcard" />} />
      <Route path="/beta/register/purchase" element={
        betaAccessValidated ? <Register /> : <Navigate to="/beta-access" replace />
      } />
    
      <Route path="/login" element={<Login />} />
      <Route path="/success" element={<Success />} />
      <Route path="/cancel " element={<Navigate to="/beta/store" />} />
      <Route path="/redeem/:giftCardId?" element={<Redeem />} />
      <Route path="/" element={<Navigate to="/beta-access" replace />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;