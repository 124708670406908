import { Box, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getMSALToken, UserData } from '../msalConfig';
import { registerAndCheckoutUser, registerAndRedeemGiftCard } from '../services/api';
import { User } from '../types/api';
import { logDebug } from '../utils/logger';
import { showErrorToast, showInfoToast } from '../utils/toastUtils';
import ConfirmGiftCardRegisterDialog from './Registration/ConfirmRegistrationCard';
import RegisterNewUser from './Registration/RegisterNewUser';
import PurchaseProductCard from './Registration/SelectedProduct';
import ExistingUserLogin from './Registration/UserLogin';

interface RegisterProps {
  // onRegister: (userToken: string, stripeSessionUrl: string) => Promise<void>;
  mode?: 'product' | 'giftcard';
}

const Register: React.FC<RegisterProps> = ({ mode = 'product' }) => {
  // const pageRefresh = useRef(true);
  const [loading, setLoading] = useState(false);
  const [msalUser, setMSALUser] = useState<UserData | null>(null);
  const [showGiftCardConfirmDialog, setShowGiftCardConfirmDialog] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { product, giftCardId, giftCard } = location.state || {};

  const productId = product?.selectedProductId ?? product?.id;
  const selectedPrice = product?.active_price?.id ?? '';
  const productQty = product?.qty;
  const { login, logout } = useAuth();


  const confirmGiftCard = useCallback(async () => {
    setLoading(true);
    logDebug('Confirming gift card registration', { msalUser, giftCardId });

    let userToken: User;
    let stripeSession: { url: string };
    let msg: string;
    try {
      ({ data: { user: userToken, session: stripeSession, message: msg } } = await registerAndRedeemGiftCard(msalUser, giftCardId));
      logDebug('Registered and redeemed gift card', { userToken, stripeSession });


      await login(userToken, userToken.token);
      if (stripeSession.url.startsWith('/')) {
        logDebug('Navigating to local path: ', stripeSession.url);
        showInfoToast(msg)
        navigate(stripeSession.url);
      }
      else {
        logDebug('Navigating to external path: ', stripeSession.url);
        window.location.href = stripeSession.url;
      }
    }
    catch (error: any) {
      logDebug('Error during gift card registration', { error });
      showErrorToast(error.message || 'Failed to register gift card');
    }
    finally {
      setLoading(false);
    }

  }, [login, navigate, giftCardId, msalUser]);

  const handleMSALLogin = useCallback(async () => {
    setLoading(true);
    logDebug('Starting MSAL login process', { mode, productId, giftCardId });


    try {
      const user = await getMSALToken();
      setMSALUser(user);
      if (!user) {
        showErrorToast('Failed to login with Microsoft');
        return;
      }
      logDebug('MSAL token obtained', { user });

      let userData: User;
      let stripeSession: { url: string };

      if (mode === 'product') {
        ({ user: userData, session: stripeSession } = await registerAndCheckoutUser(user, productId, productQty, selectedPrice));
        logDebug('Registered and checked out user for product', { userToken: userData, stripeSession });
      } else if (mode === 'giftcard') {
        setShowGiftCardConfirmDialog(true);
        return
      } else {
        showErrorToast('Invalid registration mode');
        return;
      }

      await login(userData, userData.token);
      if (stripeSession.url.startsWith('/')) {
        logDebug('Navigating to local path: ', stripeSession.url);
        navigate(stripeSession.url);
      }
      else {
        logDebug('Navigating to external path: ', stripeSession.url);
        window.location.href = stripeSession.url;
      }

    } catch (error: any) {
      logDebug('Error during MSAL login', { error });
      logout();
      showErrorToast(error.message || 'Failed to login with Microsoft');
    } finally {
      setLoading(false);
    }
  }, [login, logout, navigate, productId, giftCardId, mode, setShowGiftCardConfirmDialog, setMSALUser, productQty, selectedPrice]);

  // useEffect(() => {
  //   if (pageRefresh.current) {
  //     logDebug('Page Refreshed - Checking for existing account');
  //     pageRefresh.current = false;

  //     const account = msalInstance.getAllAccounts()[0];
  //     logDebug('Found account in MSAL', { account });

  //     if (account) {
  //       handleMSALLogin();
  //     }
  //   }
  // }, [handleMSALLogin]);


  useEffect(() => {
    if (!giftCardId) {
      logDebug('No gift card id found in location state', { location });
      //trying from query params
      if (!product) {
          showErrorToast('Invalid Link. Please try again.');
          navigate('/redeem');
      }
    }     
  }, [giftCardId, location, navigate, product]);

  return (
    <Box sx={{ mt: 4, px: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12}>
          {product && <PurchaseProductCard product={product} />}
        </Grid>
        <Grid item xs={10} md={6}>
          <ExistingUserLogin loading={loading} handleMSALLogin={handleMSALLogin} />
        </Grid>
        <Grid item xs={10} md={6}>
          <RegisterNewUser loading={loading} handleMSALLogin={handleMSALLogin} />
        </Grid>
      </Grid>
      <ConfirmGiftCardRegisterDialog
        open={showGiftCardConfirmDialog}
        user={msalUser}
        product={product}
        giftCard={giftCard}
        onConfirm={confirmGiftCard}
        onClose={() => { setShowGiftCardConfirmDialog(false) }
        } />
    </Box>
  );
};

export default Register;
